import React from "react"
import { Link } from "gatsby"
import { MdEmail } from 'react-icons/md';
import { FaBlog } from 'react-icons/fa';

import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap"

const CustomNavbar = ({ pageInfo }) => {
  return (
    <>
      <Navbar sticky="top" expand="sm" bg="dark" className='navbar-dark justify-content-end'>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className='ml-auto the-nav'>
            { pageInfo && pageInfo.pageName !== 'index' && <Nav.Link href="/">Home</Nav.Link>}
            <a className='nav-link' href='mailto:info@fullofcaffeine.com'><MdEmail/>&nbsp;Get in touch</a>
            {false && <a className='nav-link' href="https://blog.fullofcaffeine.co"><FaBlog/>&nbsp;Blog</a>}
            {false && <Nav.Link href="/page-2"><FaBlog/>&nbsp;Open Source</Nav.Link>}
            {false && <Nav.Link href="/page-2"><FaBlog/>&nbsp;Our Products</Nav.Link>}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default CustomNavbar
