/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Row, Col, Button, Nav } from "react-bootstrap"
import clsx from 'clsx';

import Header from "./header"
import Navbar from "./navBar"
import logoIcon from '../images/logo_icon.svg';
import logo from '../images/logo.svg';
import { IoIosMail, IoIosPin } from 'react-icons/io';
import { MdPhone } from 'react-icons/md';



const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Navbar pageInfo={pageInfo} />
        <Container fluid className={clsx('main', pageInfo && pageInfo.pageName)}>
          <Row noGutters className>
            <Col>
              <main>{children}</main>
            </Col>
          </Row>
       </Container>
       <Container fluid>
          <footer>
            <Row className='grey-bg pt-3 pb-1'>
              <Col xs={12} className='footer__col d-flex justify-content-center align-items-center'>
              <div className='d-none d-sm-block'>
                <img src={logoIcon} className="footer__col__logo" />
              </div>
              <div className='d-block d-sm-none'>
                <img src={logo} className="footer__col__logo" />
              </div>
              <div className='footer__col__contact_info ml-3'>
                  <p>
                    <IoIosMail />&nbsp;hello@fullofcaffeine.co<br />
                    <MdPhone />&nbsp;+1 310 876 8527
                  </p>
              </div>
              </Col>
             <Col xs={12} className='d-flex justify-content-center flex-column align-items-center'>
               <span className='footer__separator'></span>
                <span style={{ fontSize: '12px' }} className='mr-2 mt-1 mb-1'>
                  Ⓒ FullOfCaffeine, LLC, {new Date().getFullYear()}
                </span>
              </Col>
            </Row>
          </footer>
          </Container>
      </>
    )}
  />
)

export default Layout
